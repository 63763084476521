<template>
  <section class="reports">
    Home
  </section>
</template>

<script>
import { getUserData } from '@/libs/auth'

export default {
  computed:{
    profile() {
      return getUserData();
    }
  },
}
</script>

<style>

</style>
